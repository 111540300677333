<template>
  <div class="mt-10">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <img :src="require('../assets/check.svg')" class="w-16" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <div class="col text-center">
              <h2 class="mt-4">Partner Contract Executed </h2>
              <p>Thank you for signing up with us, we look forward to a mutually beneficial relationship with you.</p>
              <p> A copy of the agreement has been shared to your email.</p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignCompleted',
  components: {},
  data() {
    return {

    }
  },
  methods: {

  },
  async mounted() {

  }
};
</script>
